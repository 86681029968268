.App {
  background-color: #141414;
  color: white;
  text-align: center;
  max-width: 100%;
  /* border: 10px solid orange; */
  height: 100%;
}

.App-header{
  font-size: calc(10px + 3vmin);
  color: white;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  /* border-bottom: 2px solid whitesmoke; */
  padding: 0 3%;
}

.logo { 
  width: 80vw;
  margin-bottom: -6%;
  /* border: 1px solid red; */
}

.App nav{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: right;
  width: 100%;
  font-size: calc(10px + 2vmin);
}

.navLink { 
  border: 1px solid whitesmoke;
  border-radius: 2%;
  box-shadow: 0 0 5px rgb(255, 254, 254), 2px 2px 5px rgb(255, 253, 253);
  width: 30%;
  text-align: center;
  background-color: #141414;
  margin-top: 1%;
}

.navLink:hover { 
  background-color: #1ecbe1;
  font-size: calc(10px + 3vmin);
}


.App-header nav h3{
  text-align: right;
}

.Home {
  /* height: 100vh; */
}

.HomeInfo {
  padding: 2%;
  padding-bottom: 0;
  /* border: 1px solid red; */
  display: flex;
  background-color: #141414;
  
}


.skillh2 {
  font-size: calc(10px + 4vmin);
}


.About {
  padding: 2%;
  padding-top: 1%;
  /* border: 1px solid red; */
}

.Hero-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 4%;
  padding-bottom: 4%;
  /* border: 1px solid white; */
}

.Hero-info h1{
  font-size: calc(10px + 4vmin);
}

.highlight {
  color: #1ecbe1;
}

.Hero-image img{
  width: 500px;
}

.midLogo {
  display: flex;
  font-size: calc(10px + 3vmin);
  justify-content: space-evenly;
  border-top: 2px solid #1ecbe1;
  /* border-bottom: 2px solid #1ecbe1; */
  padding: 2% 0;
  box-shadow: 0 0 5px rgb(33, 240, 255), 4px 4px 10px rgb(48, 228, 235);
}

.midLogo a{
  text-decoration: none;
  color: white;
  border-radius: 10px;
  padding: 1%;
  box-shadow: 0 0 5px rgb(33, 240, 255), 4px 4px 10px rgb(48, 228, 235);
}

.midLogo a:hover{
  color: white;
  font-size: calc(10px + 4vmin);
}

.skills {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10%;
}

.skillList {
  border: 1px solid #1ecbe1;
  border-radius: 10px;
  width: 46%;
  /* background-color: whitesmoke; */
  /* color: #141414; */
  box-shadow: 0 0 5px rgb(33, 240, 255), 4px 4px 10px rgb(48, 228, 235);
}

.skillList h3{
  font-size: calc(10px + 3vmin);
  color: #1ecbe1;
}

.skillList ul{
  /* border: 1px solid red; */
  list-style-type: none;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  /* padding-left: 12%; */
}

.skillList p{
  /* border: 1px solid red; */
  text-align: center;
  font-size: calc(10px + 2vmin);
  margin-top: -2%;
}

.AboutCard {
  border: 4px solid #1ecbe1;
  padding: 4%;
  border-radius: 50px;
  border-style: solid;
  margin: 2% auto;
  width: 80%;
  color: black;
  background-color: whitesmoke;
}

.AboutCard h3{
  font-size: calc(10px + 2vmin);
  margin-top: 0;
}

.AboutCard p{
  font-size: calc(10px + 1.5vmin);
  width: 80%;
  margin: 0 auto;
}

/****** Projects Component ********/

.Projects {
  display: flex;
  width: 94%;
  margin: 0 auto;
  justify-content: center;
  padding: 4% 2%;
  height: 100vh;
}

.project-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Project-container a{
  text-decoration: none;
  color: white;
  border-radius: 10px;
  padding: 1%;
  box-shadow: 0 0 5px rgb(33, 240, 255), 4px 4px 10px rgb(48, 228, 235);
  font-size: calc(10px + 2vmin);
}

.Project-container a:hover{
  color: white;
  font-size: calc(10px + 3vmin);
}

.project {
  position: relative;
  width: 100%;
  color: white;
  border: 5px solid white;
  display: none;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: black;
}

.project:hover .overlay {
  opacity: 0.8;
}

.text {
  color: white;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.Resume {
  height: 100%;
}

.resumeDoc img{
  width: 80%;
  padding-bottom: 2%;
}

/* MOBILE STYLES START */

/* anything less than 800px */
@media(max-width:760px){
  *{
      max-width:100%;
      margin:0;
      padding:0;
  }

  .HomeInfo {
    padding: 2%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    padding-bottom: 0%;
  }
  
  
  .Hero-info {
    flex-direction: column;
    margin: 0;
    padding: 2%;
    padding-top: 4%;
    /* border: 1px solid white; */
    height: auto;
  }
  
  .Hero-image img{
    width: 500px;
  }

  .Project-container{
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .Project-container h2{
    padding: 2%;
  }

  .Project-container h3{
    padding: 2%;
  }

  .Project-container a{
    padding: 2%;
    width: 40%;
    margin: 0 auto;
    margin-bottom: 5%;
  }

  .project {
    box-sizing: border-box;
    width: 80%;
    margin: 0 auto;
  }

  .skills {
    flex-direction: column;
    align-items: center;
    margin: 2% auto;
    width: 80%;
  }

  .skillh2 {
    padding-top: 4%;
  }

  .skillList {
    width: 80%;
    padding: 4%;
    box-sizing: border-box;
    margin: 4% auto;
  }

}
